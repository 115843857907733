import { useEffect, useMemo, useRef, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import moment from "moment-timezone";
import DataGrid from "../grid/DataGrid";
import GridSetting from "../grid/GridSettings";
import { Confirm, Success, Warning } from "../../../config/swal";
import Modal from "../../utils/Modal";
import loaderImage from "../../../assets/loader-sm.gif";
import bigLoader from "../../../assets/loader-lg.gif";
import ViewRecord from "../form-record/ViewRecord";
import Header from "./Header";
import EditRecord from "./EditRecord";

interface HeaderText {
    formId?: string;
    formLogo?: string;
    formTitle?: string;
    fullName?: string;
    submission_date?: string;
    updatedDate?: any;
    updatedBy?: any;
    IPAddress?: any;
}

const Record = () => {
    const [gridColumns, setGridColumns] = useState([]);
    const [recordData, setRecordData] = useState([]);
    const [totalRecord, setTotalRecord] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [isGridSettingsOpen, setIsGridSettingsOpen] = useState(false);
    const [selectedRows, setSelectedRows]: any = useState([]);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [formName, setFormName] = useState(null);
    const [gridSettingsFetched, setGridSettingsFetched] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [gridSettingsData, setGridSettingsData] = useState([]);
    const [searchList, setSearchList]: any = useState({});
    const [isGridRefresh, setIsGridRefresh] = useState(false);
    const [applyHighlight, setApplyHighlight] = useState(false);
    const [title, setTitle]: any = useState(null);
    const [logo, setLogo]: any = useState(null);
    const [rdList, setRdList] = useState([]);
    const [showViewRecord, setShowViewRecord] = useState(false);
    const [showEditRecord, setShowEditRecord] = useState(false);
    const [headerText, setHeaderText] = useState<HeaderText>({});
    const [details, setDetails]: any = useState([]);
    const axios = useAxios();
    const loc: any = document.location;
    const params = new URL(loc).searchParams;
    const formId = params.get("formId");
    const editRef: any = useRef();

    const fetchFormRecords = async (page: any) => {
        setShowLoader(true);
        let Fdata: any = await axios.post("forms/form-records", { ...page, formId: formId });
        let data = Fdata.data.record;
        let form = Fdata.data.form;
        let pages = form.Metadata.Content;
        setLogo(form.Metadata.Content.logo);
        setTitle(form.Metadata.Content.title);
        let total = Fdata.total;
        let recList = pages.pages.map((p: any) => p.elements).flat(1);
        setRdList(recList);
        recList = recList.filter((x: any) => !['password'].includes(x.inputType));
        recList = recList.filter((x: any) => !['image', 'html', 'customlabel', 'expression'].includes(x.type));
        let records: any = [];
        for (let i = 0; i < recList.length; i++) {
            const el = recList[i];
            let obj: any = {};
            let choice: any = [];
            let add: any = [];
            switch (el.type) {
                case "text":
                    if (el.inputType == "date") {
                        obj = {
                            min: el.min,
                            max: el.max,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => (data ? moment(data).format("DD/MM/YYYY") : ""),
                        };
                    } else if (el.inputType == "datetime-local") {
                        obj = {
                            min: el.min,
                            max: el.max,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => (data ? moment(data).format("DD/MM/YYYY hh:mm A") : ""),
                        };
                    } else if (el.inputType == "month") {
                        obj = {
                            min: el.min,
                            max: el.max,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (el.inputType == "time") {
                        obj = {
                            min: el.min,
                            max: el.max,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (el.inputType == "color") {
                        obj = {
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (el.inputType == "email") {
                        obj = {
                            placeholder: el.placeholder,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (el.inputType == "number") {
                        obj = {
                            min: el.min,
                            max: el.max,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (el.inputType == "range") {
                        obj = {
                            min: el.min,
                            max: el.max,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (el.inputType == "url") {
                        obj = {
                            placeholder: el.placeholder,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (el.inputType == "tel") {
                        obj = {
                            placeholder: el.placeholder,
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    } else if (!el.inputType) {
                        obj = {
                            isRequired: el.isRequired ? el.isRequired : false,
                            render: (data: any) => data,
                        };
                    }
                    break;
                case "datepicker":
                    obj = {
                        min: el.startDate,
                        max: el.endDate,
                        dateFormat: el.dateFormat,
                        isRequired: el.isRequired ? el.isRequired : false,
                        render: (data: any) => {
                            if (el.dateFormat) {
                                return data ? moment(data).format(el.dateFormat) : "";
                            } else {
                                return data ? moment(data).format("MM-DD-YYYY") : "";
                            }
                        },
                    };
                    break;
                case "file":
                    obj = { render: (data: any) => (data ? "Yes" : "No") };
                    break;
                case "boolean":
                case "termsconditions":
                case "privacypolicy":
                    obj = { isRequired: el.isRequired ? el.isRequired : false, render: (data: any) => (data == true ? "Yes" : "No") };
                    break;
                case "comment":
                    obj = {
                        isRequired: el.isRequired ? el.isRequired : false,
                        render: (data: any) => {
                            if (data && data.length > 50) {
                                data = data.substr(0, 50) + "...";
                            }
                            return data;
                        },
                    };
                    break;
                case 'dropdown':
                    obj = {
                        Linkwithoptimiser:el.Linkwithoptimiser ? true : false,
                        choicesByUrl:el.choicesByUrl ? el.choicesByUrl : "",
                        isRequired: el.isRequired ? el.isRequired : false,
                        render: (data: any) => {
                            return data;
                        },
                    };
                    break;
                case 'checkbox':
                case 'tagbox':
                    obj = {
                        Linkwithoptimiser:el.Linkwithoptimiser ? true : false,
                        choicesByUrl:el.choicesByUrl ? el.choicesByUrl : "",
                        isRequired: el.isRequired ? el.isRequired : false,
                        render: (data: any) => {
                            let val = "";
                            if (data && data.length) {
                                for (let i = 0; i < data.length; i++) {
                                    val += data[i] + ", ";
                                }
                                if (val != "") val = val.slice(0, -2);
                            }
                            return val;
                        },
                    };
                    break;

                case "address":
                    let Addobj = {
                        label: el.title || el.name,
                        isVisible: true,
                        isDisabled: false,
                        isRequired: el.isRequired ? el.isRequired : false,
                        streetAddressLine1IsRequired: el.streetAddressLine1IsRequired ? el.streetAddressLine1IsRequired : false,
                        streetAddressLine2IsRequired: el.streetAddressLine2IsRequired ? el.streetAddressLine2IsRequired : false,
                        isFixed: false,
                        field: el.valueName,
                        type: el.type || null,
                        columnSearch: true,
                        render: (data: any) => {
                            if (data) {
                                let newAdd: any = "";
                                newAdd += data.streetAddressLine1 && el.showStreetAddressLine1 == undefined ? data.streetAddressLine1 : "";
                                newAdd += data.streetAddressLine1 && el.showStreetAddressLine1 == undefined && data.streetAddressLine2 && el.showStreetAddressLine2 == undefined ? ", " : "";
                                newAdd += data.streetAddressLine2 && el.showStreetAddressLine2 == undefined ? data.streetAddressLine2 : "";
                                if (newAdd && newAdd.length > 50) {
                                    newAdd = newAdd.substr(0, 50) + "...";
                                }
                                return newAdd;
                            } else {
                                return data;
                            }
                        },
                    };
                    if (el.showStreetAddressLine1 == undefined || el.showStreetAddressLine2 == undefined) add.push(Addobj);
                    let Cityobj = {
                        label: el.cityLabel || "City",
                        isVisible: true,
                        isDisabled: false,
                        isRequired: el.isRequired ? el.isRequired : false,
                        cityIsRequired: el.cityIsRequired ? el.cityIsRequired : false,
                        isFixed: false,
                        field: el.valueName + ".city",
                        type: el.type || null,
                        columnSearch: true,
                        render: (data: any) => {
                            return data ? data : "";
                        },
                    };
                    if (el.showCity == undefined) add.push(Cityobj);

                    let Stateobj = {
                        label: el.stateLabel || "State",
                        isVisible: true,
                        isDisabled: false,
                        isRequired: el.isRequired ? el.isRequired : false,
                        stateIsRequired: el.stateIsRequired ? el.stateIsRequired : false,
                        isFixed: false,
                        field: el.valueName + ".state",
                        type: el.type || null,
                        columnSearch: true,
                        render: (data: any) => {
                            return data ? data : "";
                        },
                    };
                    if (el.showState == undefined) add.push(Stateobj);
                    let countryobj = {
                        label: el.countryLabel || "Country",
                        isVisible: true,
                        isDisabled: false,
                        isFixed: false,
                        isRequired: el.isRequired ? el.isRequired : false,
                        countryIsRequired: el.countryIsRequired ? el.countryIsRequired : false,
                        field: el.valueName + ".country",
                        type: el.type || null,
                        columnSearch: true,
                        render: (data: any) => {
                            return data ? data : "";
                        },
                    };
                    if (el.showCountry == undefined) add.push(countryobj);
                    let postalCodeobj = {
                        label: el.postalCodeLabel || "Postal Code",
                        isVisible: true,
                        isDisabled: false,
                        isFixed: false,
                        isRequired: el.isRequired ? el.isRequired : false,
                        postalCodeIsRequired: el.postalCodeIsRequired ? el.postalCodeIsRequired : false,
                        field: el.valueName + ".postalCode",
                        type: el.type || null,
                        columnSearch: true,
                        render: (data: any) => {
                            return data ? data : "";
                        },
                    };
                    if (el.showPostalCode == undefined) add.push(postalCodeobj);

                    records.push(...add);
                    break;

                case "fullname":
                    obj = {
                        isRequired: el.isRequired ? el.isRequired : false,
                        prefixRequired: el.prefixRequired ? el.prefixRequired : false,
                        firstNameRequired: el.firstNameRequired ? el.firstNameRequired : false,
                        middleNameRequired: el.middleNameRequired ? el.middleNameRequired : false,
                        lastNameRequired: el.lastNameRequired ? el.lastNameRequired : false,
                        render: (data: any) => {
                            if (data) {
                                let newName: any = "";
                                newName += data.prefixDropdown && el.prefixVisible && el.prefixFieldType != "text" ? data.prefixDropdown + " " : "";
                                newName += data.prefixText && el.prefixVisible && el.prefixFieldType == "text" ? data.prefixText + " " : "";
                                newName += data.firstName ? data.firstName + " " : "";
                                newName += data.middleName && el.middleNameVisible ? data.middleName + " " : "";
                                newName += data.lastName ? data.lastName + " " : "";
                                return newName;
                            } else {
                                return data;
                            }
                        },
                    };
                    break;
                default:
                    obj = {
                        isRequired: el.isRequired ? el.isRequired : false,
                        render: (data: any) => {
                            return data;
                        },
                    };
                    break;
            }
            if (el.type != "address") {
                obj["label"] = el.title || el.name;
                obj["isVisible"] = true;
                obj["isDisabled"] = false;
                obj["isFixed"] = false;
                obj["field"] = el.valueName;
                obj["type"] = el.type || null;
                obj["inputType"] = el.inputType || null;
                obj["columnSearch"] = true;
                if (el.choices && el.choices.length) el.choices.map((x: any) => choice.push({ Key: x, Value: x }));
                obj["choices"] = choice;
                records.push(obj);
            }
        }
        let cols: any = [];
        cols.push(
            {
                label: "Submited On",
                isVisible: true,
                isDisabled: false,
                isFixed: false,
                field: "CreatedAt",
                columnSearch: true,
                inputType: "date",
                render: (data: any) => (data ? moment(data).format("DD/MM/YYYY hh:mm A") : ""),
            },
            {
                label: "Record Id",
                isVisible: true,
                isDisabled: false,
                isFixed: false,
                field: "_id",
                columnSearch: true,
                inputType: "text",
                render: (data: any) => (data ? data : ""),
            },
            ...records
        );
        setGridColumns(cols);
        setRecordData(data);
        setTotalRecord(total);
        setFormName(form.Name);
        setShowLoader(false);
    };

    const fetchGridSettings = async () => {
        const { data } = await axios.get("common/grid-setting", { params: { type: "FormRecords_" + formId } });
        if (data && data.Columns.length && gridColumns.length) {
            let result: any = gridColumns.map((objA2: any) => {
                let matchingObjA1 = data.Columns.find((objA1: any) => objA1.field === objA2.field);
                if (matchingObjA1) {
                    if (matchingObjA1.sequence) objA2["sequence"] = matchingObjA1.sequence;
                    if (matchingObjA1.width) objA2["width"] = matchingObjA1.width;
                    objA2["isVisible"] = matchingObjA1.isVisible === true ? true : false;
                } else {
                    objA2["isVisible"] = true;
                }
                return { ...objA2 };
            });
            result = result.sort((a: any, b: any) => a.sequence - b.sequence);
            setGridColumns(result);
            setGridSettingsFetched(true);
            const newColumn = {
                label: "Sr. no.",
                isDisabled: true,
                isFixed: true,
                isVisible: true,
                field: "tp_serialNumber",
                columnSearch: false,
            };
            const updatedResult = [newColumn, ...result];
            const filteredColumns: any = updatedResult.filter((column: any) => column.label !== "Action");
            setGridSettingsData(filteredColumns);
        } else {
            setGridSettingsFetched(true);
        }
    };

    const generateColumns = (definitions: any) => {
        return [
            {
                Header: "Sr. no.",
                accessor: "tp_serialNumber",
                Cell: ({ row }: any) => row.index + 1,
                width: 80,
            },
            ...definitions
                .filter((col: any) => col.isVisible)
                .map((col: any) => ({
                    Header: col.label,
                    accessor: col.field,
                    width: col.field === "CreatedAt" || col.type === "datepicker" || col.inputType === "date" || col.inputType === "datetime-local" ? 265 : col.type === "tagbox" || col.type === "dropdown" || col.type === "checkbox" ? 200 : 150,
                    Cell: ({ cell: { value }, row: { original } }: any) => {
                        if (col.field === "CreatedAt") return moment(value).format("DD/MM/YYYY h:mm A");
                        switch (col.type) {
                            case "boolean":
                            case "privacypolicy":
                            case "termsconditions":
                                return value ? "Yes" : "No";

                            case "tagbox":
                            case "checkbox":
                                return Array.isArray(value) ? value.join(", ") : value;

                            case "monthfield":
                                if (col.monthOptions) {
                                    const option = col.monthOptions.find((opt: any) => opt.value === value);
                                    return option ? option.text : value;
                                }
                                return value;

                            case "fullname":
                                const { prefixDropdown, prefixText, firstName, middleName, lastName } = value || {};
                                return `${prefixDropdown || prefixText || ""} ${firstName || ""} ${middleName || ""} ${lastName || ""}`.trim();

                            case "dropdown":
                                return value ? value : "";

                            case "file":
                                return value ? "Yes" : "No";

                            case "phonenumber":
                                return value ? value : "";

                            case "datepicker":
                                if (col.dateFormat) return value ? moment(value).format(col.dateFormat) : "";
                                else return value ? moment(value).format("MM-DD-YYYY") : "";

                            case "address":                                
                                if (!col.field.includes(".city") && !col.field.includes(".country") && !col.field.includes(".postalCode") && !col.field.includes(".state")) {
                                    const { streetAddressLine1, streetAddressLine2 } = value || {};
                                    let address = streetAddressLine1 || "";
                                    if (streetAddressLine2) {
                                        address += `, ${streetAddressLine2}`;
                                    }
                                    return address;
                                } else {
                                    return value;
                                }

                            default:
                                if (typeof value === "object" && !Array.isArray(value) && value !== null) {
                                    return <pre>{JSON.stringify(value, null, 2)}</pre>;
                                } else if (col.inputType === "date") {
                                    return value ? moment(value).format("DD-MM-YYYY") : "";
                                } else if (col.inputType === "datetime-local") {
                                    return value ? moment.utc(value).format("DD-MM-YYYY h:mm A") : "";
                                    // return value ? moment(value).format("DD-MM-YYYY h:mm A") : '';
                                } else {
                                    return Array.isArray(value) ? value.join(", ") : value?.toString();
                                }
                        }
                    },
                    Linkwithoptimiser:col.Linkwithoptimiser ? col.Linkwithoptimiser : "",
                    choicesByUrl:col.choicesByUrl ? col.choicesByUrl : "",
                    inputType:col.inputType,
                    type: col.type ? col.type : col.field,
                    choices: col.choices ? col.choices : [], // Add type here
                })),
        ];
    };

    useEffect(() => {
        if (gridColumns.length) {
            fetchGridSettings();
        }
    }, [recordData, isGridSettingsOpen]);

    useEffect(() => {
        fetchFormRecords({ offset: pageIndex + 1, limit: pageSize, searchStr: {}, field: "Name" });
    }, [pageIndex, pageSize]);

    const handlePageChange = (newPageIndex: any) => {
        setPageIndex(newPageIndex);
    };

    const handlePageSizeChange = (newPageSize: any) => {
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    const deleteRecords = async () => {
        let result = await Confirm({
            html: "<span style='font-size:15px; color:#686868;'>Are you sure you want to delete this record? <br> Once deleted, you will not be able to recover this.</span>",
            customClass: {
                confirmButton: "custom-delete-confirm-btn", // Use a unique class
                cancelButton: "custom-delete-cancel-btn", // Use a unique class
            },
        });
        if (result.isConfirmed) {
            setLoading(true);
            await axios.post("forms/remove-form-records", { _ids: selectedRows.map((r: any) => r._id), FormId: formId });
            setLoading(false);
            Success({ title: "Record(s) deleted" });
            fetchFormRecords({ offset: pageIndex + 1, limit: pageSize, searchStr: {} });
            setShowEditRecord(false);
            setShowViewRecord(false);
        }
    };

    const handleExportModalOpen = () => {
        setOpenExportModal(true);
    };

    const refreshGrid = () => {
        fetchFormRecords({ offset: pageIndex + 1, limit: pageSize, searchStr: {}, field: "Name" });
        setIsGridRefresh(!isGridRefresh);
    };

    const handleSearchListChange = (updatedSearchList: any) => {
        setSearchList(updatedSearchList);
        console.log("Updated search list:", updatedSearchList);
    };

    const searchRecords = () => {
        let isValid = true;
        for (const key in searchList) {
            if (key === "_id") {
                const pattern = /[0-9a-fA-F]{24}/;
                if (!pattern.test(searchList[key])) {
                    isValid = false;
                    return Warning({
                        title: `Please enter a valid id`,
                    });
                }
            } else if (key === "CreatedAt") {
                if (searchList[key].from && searchList[key].from) {
                    if (searchList[key].from > searchList[key].to) {
                        isValid = false;
                        return Warning({
                            title: `Please select proper date range`,
                        });
                    }
                }
            } else if (typeof searchList[key] == "object") {
                if (searchList[key].from && searchList[key].from) {
                    if (searchList[key].from > searchList[key].to) {
                        isValid = false;
                        return Warning({
                            title: `Please select proper date range`,
                        });
                    }
                }
            }
            if (key.includes("_from")) {
                delete searchList[key];
            }
            if (key.includes("_to")) {
                delete searchList[key];
            }
        }
        if (isValid) {
            setApplyHighlight(true);
            fetchFormRecords({ offset: pageIndex + 1, limit: pageSize, searchStr: searchList, field: "Name" });
        }
    };

    const columns = useMemo(() => generateColumns(gridColumns), [gridColumns]);

    const openDetails = async (row: any) => {
        row = { ...row };
        const { mapped, submissionDate, fullName } = await mapData(row, gridColumns);
        if (mapped.length !== 0) setDetails(mapped);
        setHeaderText((prevHeaderText) => ({
            ...prevHeaderText,
            updatedDate: moment(row?.UpdatedDate).format("DD-MM-YYYY h:mm A"),
            updatedBy: row.UpdatedBy ? (row.UpdatedBy.LastName ? row.UpdatedBy.FirstName + " " + row.UpdatedBy.LastName : row.UpdatedBy.FirstName) : "",
            IPAddress: row?.UserAgent?.IPAddress,
            fullName: fullName,
            submission_date: submissionDate,
            formTitle: title,
            formLogo: logo,
        }));
        setShowViewRecord(true);
        setSelectedRows([row]);
    };

    const closeDetails = () => {
        setShowViewRecord(false);
        setShowEditRecord(false);
        setDetails([]);
        setSelectedRows([]);
    };

    const mapData = (row: any, record: any) => {
        const fields = record;
        let fullName;
        let submissionDate;
        const addArray: any[] = [];
        let mapped = fields.map((field: any) => {
            const matchedValue = row[field.field];
            if (matchedValue !== undefined) {
                if (row[`${field.field}_fullName`]) {
                    fullName = row[`${field.field}_fullName`];
                }
                field[field.field] = matchedValue;
            } else {
                // If ID is not present in the row object, add field with empty data
                field[field.field] = "";
            }

            if (field.type === "fullname") {
                field[field.field] = field[field.field] || {};
                const { prefixDropdown = "", firstName = "", middleName = "", lastName = "", prefixText = "" } = field[field.field];
                field[field.field] = { prefixDropdown, firstName, middleName, lastName, prefixText };
                const forPrefixChoice: any = rdList.find((m: any) => m.valueName === field.field);
                field = { ...field, ...forPrefixChoice };
            }
            if (field.type === "address") {
                addArray.push(field);
                field[field.field] = field[field.field] || {};
                const { streetAddressLine1 = "", streetAddressLine2 = "", city = "", state = "", country = "", postalCode = "" } = field[field.field];
                field[field.field] = { streetAddressLine1, streetAddressLine2, city, state, country, postalCode };
            }
            submissionDate = row["CreatedAt"];
            field["_id"] = row["_id"];
            if (field.type === "monthfield") {
                const months: any = rdList.find((m: any) => m.valueName === field.field);
                field = { ...field, ...months };
            }
            if (field.type === "date" || field.type === "datepicker") {
                const date: any = rdList.find((m: any) => m.valueName === field.field);
                field = { ...field, ...date };
            }
            return field;
        });

        addArray.forEach((addField) => {
            mapped.forEach((mappedField: any) => {
                if (mappedField.field) {
                    const cityId = mappedField.field.split(".city")[0];
                    const stateId = mappedField.field.split(".state")[0];
                    const countryId = mappedField.field.split(".country")[0];
                    const postalCodeId = mappedField.field.split(".postalCode")[0];

                    if (cityId === addField.field && mappedField.field.includes(".city")) {
                        mappedField[mappedField.field] = addField[cityId].city;
                    }
                    if (stateId === addField.field && mappedField.field.includes(".state")) {
                        mappedField[mappedField.field] = addField[stateId].state;
                    }
                    if (countryId === addField.field && mappedField.field.includes(".country")) {
                        mappedField[mappedField.field] = addField[countryId].country;
                    }
                    if (postalCodeId === addField.field && mappedField.field.includes(".postalCode")) {
                        mappedField[mappedField.field] = addField[postalCodeId].postalCode;
                    }
                }
            });
        });
        mapped = mapped.filter((d: any) => d.field !== "CreatedAt" && d.field !== "_id");
        return { mapped, fullName, submissionDate };
    };

    const saveEditRecord = async () => {
        const data = await editRef.current.saveFormData();
        if (data) {
            setShowLoader(true);
            Success({ title: "Record(s) updated" });
            setShowEditRecord(false);
            setShowViewRecord(false);
            setDetails([]);
            setHeaderText({});
            fetchFormRecords({ offset: pageIndex + 1, limit: pageSize, searchStr: {}, field: "Name" });
            setShowLoader(false);
        }
    };

    const downloadPdf = async () => {
        try {
            const response: any = await axios.post("forms/download-pdf", { details: [...details], Name: formName, headerText: headerText }, { responseType: "blob" });
            let url = window.URL.createObjectURL(response);
            let link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("hidden", "true");
            link.setAttribute("download", `${formName}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading PDF:", error);
        }
    };

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <img src={bigLoader} alt="Loading..." />
                </div>
            )}
            <div className="whole-container">
                <div className="record-container">
                    <div className="d-flex pl-0 pr-0 pt-0">
                        <div className="flex-grow-1 mb-2">
                            <h2 className="h4 mb-0">Record</h2>
                            <span className="small pt-2">{totalRecord} items</span>
                        </div>
                        <div className="text-right flex-grow-1 gird-button-list hide-edit mb-2 mt-1">
                            <div className="d-flex justify-content-end pad-right20">
                                <div className="tooltip-container">
                                    <button className="btnSearch btn btn-sm btn-outline-secondary" onClick={() => searchRecords()}>
                                        <i className="fas fa-search"></i>
                                    </button>
                                    <span className="tooltip-text">Search</span>
                                </div>
                                <div className="tooltip-container">
                                    <button className="btnRefresh btn btn-sm btn-outline-secondary" onClick={() => refreshGrid()}>
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                    <span className="tooltip-text">Refresh</span>
                                </div>
                                <div className="tooltip-container">
                                    <button className="btn btn-sm btn-outline-secondary" onClick={() => setIsGridSettingsOpen(true)}>
                                        <i className="fas fa-cog"></i>
                                    </button>
                                    <span className="tooltip-text">Grid setting</span>
                                </div>
                                <div className="tooltip-container deleteBtn">
                                    <button className="btnDelete btn btn-sm btn-outline-secondary deletePages" onClick={() => deleteRecords()} disabled={selectedRows.length === 0}>
                                        <i className="fas fa-trash"></i>
                                    </button>
                                    <span className="tooltip-text">Delete selected list</span>
                                </div>
                                <div className="tooltip-container">
                                    <button className="btn btn-primary" onClick={() => handleExportModalOpen()}>
                                        Export
                                    </button>
                                    {/* <span className="tooltip-text">Export</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>{openExportModal && <Modal closeModal={setOpenExportModal} recordData={recordData} selectedRows={selectedRows} formId={formId} formName={formName} />}</div>
                    <div>{isGridSettingsOpen && formId && <GridSetting columns={gridColumns} formId={formId ?? ""} setIsGridSettingsOpen={setIsGridSettingsOpen} fetchSettings={fetchGridSettings} gridSettingsData={gridSettingsData} />}</div>

                    {columns && <DataGrid columns={columns} data={recordData} pageIndex={pageIndex} pageSize={pageSize} totalRecord={totalRecord} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} onSelectionChange={setSelectedRows} onSearchListChange={handleSearchListChange} searchFormRecords={searchRecords} gridRefresh={isGridRefresh} highlight={applyHighlight} setHighlight={setApplyHighlight} viewDetails={openDetails} loader={showLoader} gridOptions={{}} />}
                </div>
            </div>
            {
                (showViewRecord || showEditRecord) && (
                    <div className="right-panel show">
                        <Header headerText={headerText} closeDetails={closeDetails} formId={formId} setShowEditRecord={setShowEditRecord} setShowViewRecord={setShowViewRecord} showEditRecord={showEditRecord} saveEditRecord={saveEditRecord} downloadPdf={downloadPdf} deleteRecords={deleteRecords}/>
                        {(details.lenth !== 0 && showViewRecord) && <ViewRecord recordDetail={details}/>}
                        {(details.lenth !== 0 && showEditRecord) && <EditRecord ref={editRef} recordDetail={details} formId={formId} columns={columns}/>}
                    </div>
                )
            }
            <style>
                {`
                .fontSize {
                    font-size: 11px;
                }
                    .right-panel {
                        height: calc(100vh - 107px);
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: #fff;
                        z-index: 100;
                        width: 500px;
                        padding: 1.5rem;
                        overflow: auto;
                        transition: all .3s;
                        margin-right: 0;
                        box-shadow: 0 0 20px rgba(0,0,0,.1);
                    }

                    .right-panel.show {
                        transform: translateX(0);
                    }
                    .col-6 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                    .text-lg-right {
                        text-align: right !important;
                    }
                    .ml-2, .mx-2 {
                        margin-left: 0.5rem !important;
                    }
                    .pb-3, .py-3 {
                        padding-bottom: 1rem !important;
                    }
                    
                    .border-bottom {
                        border-bottom: 1px solid #e3e6f0 !important;
                    }
                    .row {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: -0.5rem;
                        margin-left: -0.5rem;
                    }
                    
                    .btn-sm{
                        padding: 0.25rem 0.5rem;
                        font-size: 0.7109375rem;
                        line-height: 1.5;
                        border-radius: 0.2rem;
                    }
                    .btn-primary {
                        color: #fff;
                        background-color: #075da8;
                        border-color: #075da8;
                    }
                `}
            </style>
        </>
    );
};

export default Record;
