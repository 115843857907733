import React, { useEffect, useRef, useState } from "react";
import useAxios from '../../../../../hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, updateIntegration } from '../../../../../store';
import { Success, Warning, Confirm, Error } from "../../../../../config/swal";
import FormSection from "./FormSection";

const CreateRecord = ({ handleNextStep, optimiserObjects, setFormEditable }: any) => {
	//const [FieldsMap, setFieldsMap] = useState([{ value: "" }]);
	const dispatch = useDispatch();
	const initialSections = [
		{
			FieldsMap: [{ FormFieldName: "", OptimiserFieldName: "" }],
			ObjectName: "",
			ObjectFields: [],
			validationMessages: {
				ObjectName: '',
				FieldsMap: [{ FormFieldName: '', OptimiserFieldName: '' }],
			}
		},
	]
	const [sections, setSections] = useState(initialSections);
	const [isEdit, setIsEdit] = useState(false);
	const [isFormDisabled, setIsFormDisabled] = useState(true);
	const [formStatus, setFormStatus] = useState("Enable");
	//const [optimiserObjects, setoptimiserObjects] = useState([]);
	//const [isLoading, setIsLoading] = useState(true);
	const [actionName, setActionName] = useState("create");
	const [items, setItems] = useState([]);
	const [filteredObjectFields, setFilteredObjectFields] = useState({});
	const formData = useSelector((state: RootState) => state.project.formData);
	let intSettings:any = null;
	const fieldTypeMapping: any = {
		boolean: ["checkbox"],
		privacypolicy: ["checkbox"],
		termsconditions: ["checkbox"],
		tagbox: ["multiselect", "text", "textarea","multilookup"],
		checkbox: ["multiselect", "text", "textarea","checkbox"],
		monthfield: ["text"],
		fullname: ["text"],
		address: ["text", "textarea", "texteditor"],
		city: ["lookup"],
		country: ["dropdown"],
		dropdown: ["dropdown", "text", "lookup", "multilookup", "objectid"],
		phonenumber: ["phone", "text"],
		datepicker: ["date", "text"],
		emailfield: ["email", "text"],
		text: ["text","objectid"],
		comment: ["textarea","texteditor"],
		file: ["image","file"],
		radiogroup: ["dropdown", "text"],
		number: ["int", "decimal", "text"],
		tel: ["phone", "text"],
		color: ["text"],
		date: ["date", "text"],
		"datetime-local": ["datetime", "text"],
		email: ["email", "text"],
		month: ["text"],
		password: [], // Not applicable
		range: ["int", "decimal", "text"],
		time: ["time"],
		url: ["url", "text"],
		week: ["text"],
	};

	if (formData.Settings.Integration) {
		intSettings = { ...formData.Settings.Integration };
		intSettings = JSON.parse(JSON.stringify(intSettings))
		//console.log(intSettings);
	}

	const integrationData: any = useSelector((state: RootState) => state.project.integration);
	const axios = useAxios();

	const handleAddSection = (e: any) => {
		e.preventDefault();
		setSections([...sections, {
			FieldsMap: [{ FormFieldName: "", OptimiserFieldName: "" }], ObjectName: "", ObjectFields: [], validationMessages: {
				ObjectName: '',
				FieldsMap: [{ FormFieldName: '', OptimiserFieldName: '' }],
			}
		}]);
	};

	const handleAddField = (e: any, sectionIndex: number) => {
		e.preventDefault();
		const newSections = sections.map((section, i) => {
			if (i === sectionIndex) {
				const optimiserFieldNames:any = section.FieldsMap.map((fieldMap: any) => fieldMap.OptimiserFieldName);
				const filteredObjectFields = section.ObjectFields.filter((objectField: any) =>
					objectField?.value && !optimiserFieldNames.includes(objectField.value)
				);
				setFilteredObjectFields((prevState: any) => ({
					...prevState,
					[section.ObjectName]: filteredObjectFields
				}));
				return {
					...section, FieldsMap: [...section.FieldsMap, { FormFieldName: "", OptimiserFieldName: "" }], validationMessages: {
						ObjectName: section.validationMessages.ObjectName, FieldsMap: [...section.validationMessages.FieldsMap, { FormFieldName: '', OptimiserFieldName: '' }]
					}
				};
			}
			return section;
		});
		
		setSections(newSections);
		console.log(newSections,"===");
		
	};

	const handleDeleteField = (sectionIndex: number, fieldIndex: number) => {
		if (sections[sectionIndex].FieldsMap.length <= 1) {
			Warning({ title: "You cannot delete all fields. Please keep at least one field." })
			return;
		}
		const newSections = sections.map((section, i) => {
			if (i === sectionIndex) {
				const newFieldsMap = section.FieldsMap.filter((_: any, j: number) => j !== fieldIndex);
				const valiMessage = section.validationMessages.FieldsMap.filter((_: any, j: number) => j !== fieldIndex);
				
				return { ...section, FieldsMap: newFieldsMap,validationMessages:{...section.validationMessages,FieldsMap:valiMessage} };
			}
			return section;
		});
		setSections(newSections);
	};

	const handleObjectDelete = (sectionIndex: number) => {
		if (sections.length <= 1) {
			Warning({ title: "You cannot delete all sections. Please keep at least one section." })
			return;
		}
		const newSections2 = sections.filter((_: any, j: number) => j !== sectionIndex);
		setSections(newSections2);
	};

	useEffect(() => {
		let fitems = formData.Metadata.Content.pages?.map((p: any) => p.elements || []).flat(1) || [];
		//console.log(fitems)
		let newItems: any = [];
		for (const item of fitems) {
			//if (['html', 'image', 'file', 'customlabel'].includes(item.type)) continue;
			switch (item.type) {
				case 'fullname':
					if (item.prefixVisible) newItems.push({ name: `${item.name} (Prefix)`, valueName: `{${item.name}.prefix}`, label: `${item.title} (Prefix)`, type:item.type });
					newItems.push({ name: `${item.name} (First Name)`, valueName: `{${item.name}.firstName}`, label: `${item.title} (First Name)`, type:item.type });
					if (item.middleNameVisible) newItems.push({ name: `${item.name} (Middle Name)`, valueName: `{${item.name}.middleName}`, label: `${item.title} (Middle Name)`, type:item.type })
					newItems.push({ name: `${item.name} (Last Name)`, valueName: `{${item.name}.lastName}`, label: `${item.title} (Last Name)`, type:item.type });
					break;
				case 'address':
					if (item.showStreetAddressLine1 == undefined) newItems.push({ name: `${item.name} (St. Address 1)`, valueName: `{${item.name}.streetAddressLine1}`, label: `${item.title} (St. Address 1)`, type:item.type })
					if (item.showStreetAddressLine2 == undefined) newItems.push({ name: `${item.name} (St. Address 2)`, valueName: `{${item.name}.streetAddressLine2}`, label: `${item.title} (St. Address 2)`, type:item.type })
					if (item.showCity == undefined) newItems.push({ name: `${item.name} (City)`, valueName: `{${item.name}.city}`, label: `${item.title} (City)`, type:"city" })
					if (item.showState == undefined) newItems.push({ name: `${item.name} (State)`, valueName: `{${item.name}.state}`, label: `${item.title} (State)`, type:item.type })
					if (item.showCountry == undefined) newItems.push({ name: `${item.name} (Country)`, valueName: `{${item.name}.country}`, label: `${item.title} (Country)`, type:"country" })
					if (item.showPostalCode == undefined) newItems.push({ name: `${item.name} (Postal Code)`, valueName: `{${item.name}.postalCode}`, label: `${item.title} (Postal Code)`, type:item.type })
					break;
				// case 'customlabel':
				//     newItems.push({name: `${item.labelText || 'Label'}`, valueName: `${item.labelText || 'Label'}`})
				//     newItems.push({name: `${item.subLabelText || 'Sub Label'}`, valueName: `${item.subLabelText || 'Sub Label'}`})
				// break;
				default:
					if(item.inputType) newItems.push({ name: `${item.name}`, valueName: `{${item.name}}`, label: `${item.title}`,type:item.inputType})
					else newItems.push({ name: `${item.name}`, valueName: `{${item.name}}`, label: `${item.title}`,type:item.type})
					break;
			}
		}

		newItems = newItems.map((i: any) => ({ ...i, value: i.name }))
		newItems = newItems.sort((a:any, b:any) => a.label.localeCompare(b.label));
		setItems(newItems);
		// test
		if(intSettings && intSettings.Optimiser && intSettings.Optimiser.status) setFormStatus(intSettings.Optimiser.status);
		if (!intSettings) setIsFormDisabled(false);

		if (intSettings && intSettings.Optimiser && intSettings.Optimiser.create) setIsEdit(true);

		if (integrationData && integrationData.create) {
			setActionName(integrationData.action);
			setSections(integrationData.create);
			setIsEdit(true);
			setIsFormDisabled(true);
		} else if (intSettings && intSettings.Optimiser && intSettings.Optimiser.create) {
			setActionName(intSettings.Optimiser.action);
			let newSections = intSettings.Optimiser.create.map((s: any) => {
				let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == s.ObjectName);
				let fieldsMap: any = [];
				if (selectedObject) {
					s.FieldsMap.forEach((element: any) => {
						fieldsMap.push({ FormFieldName: '', OptimiserFieldName: ''})
					});
				}
				return {
					FieldsMap: s.FieldsMap,
					ObjectName: s.ObjectName,
					ObjectFields: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f?.Name, label: f?.DisplayName + " (" + f?.UIDataType + ")", UIDataType:f?.UIDataType, IsRequired:f?.IsRequired, DefaultValue:f?.DefaultValue})) : [],
					validationMessages: {
						ObjectName: "",
						FieldsMap: fieldsMap,
					}
				}
			})
			///console.log(newSections, "++++++++++++++++++++")
			newSections.map((section:any) => {
				const optimiserFieldNames:any = section.FieldsMap.map((fieldMap: any) => fieldMap.OptimiserFieldName);
				const filteredObjectFields = section.ObjectFields.filter((objectField: any) =>
					objectField?.value && !optimiserFieldNames.includes(objectField.value)
				);
				setFilteredObjectFields((prevState: any) => ({
					...prevState,
					[section.ObjectName]: filteredObjectFields
				}));
			});
			setSections(newSections);
		}

		//if ( && integrationData.create) 
		//if (integrationData && integrationData.action) setActionName(integrationData.action);



	}, []);

	const handleObjectChange = (sectionIndex: number, value: any) => {
		//if (value) {
		const newSections = sections.map((section, i) => {
			if (i === sectionIndex) {
				let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == value);
				const fieldsMap = selectedObject && selectedObject.Fields.filter((f: any) => f && f?.IsRequired && f?.Name != "OwnerID" && f?.DefaultValue === undefined)
                    .map((f: any) => ({
                        FormFieldName: "", 
                        OptimiserFieldName: f.Name 
                    }))
					setFilteredObjectFields((prevState: any) => ({
						...prevState,
						[section.ObjectName]: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f?.Name, label: f?.DisplayName + " (" + f?.UIDataType + ")", UIDataType:f?.UIDataType, IsRequired:f?.IsRequired, DefaultValue:f?.DefaultValue})) : []
					}));
				return { ...section, ObjectName: value, validationMessages:{ ObjectName:value, FieldsMap:fieldsMap && fieldsMap.length ? fieldsMap.map(() => ({ FormFieldName: "", OptimiserFieldName: "" })) : [] }, FieldsMap: fieldsMap && fieldsMap.length ? [...fieldsMap] : [{ FormFieldName: "", OptimiserFieldName: "" }], ObjectFields: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f?.Name, label: f?.DisplayName + " (" + f?.UIDataType + ")", UIDataType:f?.UIDataType, IsRequired:f?.IsRequired, DefaultValue:f?.DefaultValue})) : [] };
			}
			return section;
		});
		setSections(newSections);
		validateObjectName(newSections, sectionIndex, value)
		//}
		//validateUniqueValues(newSections);
	};

	const handleInputChange = (
		sectionIndex: number,
		groupIndex: number,
		field: any,
		value: string,
		fieldType: any
	  ) => {
		const newSections = sections.map((section, i) => {
		  if (i !== sectionIndex) return section;
			
		  let optimiserFieldNames = section.FieldsMap.map((fieldMap: any) => fieldMap.OptimiserFieldName);
	  
		  const newGroups = section.FieldsMap.map((group, j) => {
			if (j !== groupIndex) return group;
	  
			const filteredOptimiserField:any = section.ObjectFields.find(
			  (objField: any) => objField.value === group.OptimiserFieldName
			);
	  
			if (field === "FormFieldName" && ((filteredOptimiserField && !filteredOptimiserField.IsRequired) || !group.OptimiserFieldName)) {
			  if (value) {
				const filterFields: any = section.ObjectFields.filter((objField: any) => {
					// if (fieldType === "city") {
					//   return objField.UIDataType === "lookup" && objField.value === "City";
					// }
					if (objField.IsRequired) {
					  return fieldTypeMapping[fieldType]?.includes(objField.UIDataType) && objField.value && !optimiserFieldNames.includes(objField.value);
					} else {
					  return fieldTypeMapping[fieldType]?.includes(objField.UIDataType) && objField.value;
					}
				  }).map((f: any) => ({
				  value: f.value,
				  label: f.label,
				  UIDataType: f.UIDataType,
				}));
	  
				const matchFound = filterFields.some((f: any) =>
				  fieldTypeMapping[fieldType]?.includes(f.UIDataType) && f.value === group.OptimiserFieldName
				);
				
				setFilteredObjectFields((prevState: any) => ({
					...prevState,
					[section.ObjectName]: filterFields
				}));
				return {
				  ...group,
				  OptimiserFieldName: matchFound ? group.OptimiserFieldName : "",
				  [field]: value,
				};
			  } else {
				const filteredObjectFields = section.ObjectFields.filter(
				  (objField: any) => objField.value && !optimiserFieldNames.includes(objField.value)
				);
				setFilteredObjectFields((prevState: any) => ({
					...prevState,
					[section.ObjectName]: filteredObjectFields
				}));
			  }
			}else if(field === "OptimiserFieldName" && !value){
				optimiserFieldNames = optimiserFieldNames.filter(
					(optName: string) => optName !== group.OptimiserFieldName
				  );
				const filteredObjectFields = section.ObjectFields.filter(
					(objField: any) => objField.value && !optimiserFieldNames.includes(objField.value)
				  );
				  setFilteredObjectFields((prevState: any) => ({
					...prevState,
					[section.ObjectName]: filteredObjectFields
				}));
			}
	  
			return { ...group, [field]: value };
		  });
	  
		  return { ...section, FieldsMap: newGroups };
		});
	  
		validateGroupFieldValues(newSections, sectionIndex, groupIndex, field, value, fieldType);
	};
	  

	const validateGroupFieldValues = (newSections: any, sectionIndex: number, groupIndex: number = -1, field: any = null, value: string = "", fieldType?:any) => {
		let newSections2 = JSON.parse(JSON.stringify(newSections))
		if (!newSections2[sectionIndex]?.validationMessages) {
			newSections2[sectionIndex].validationMessages = { ObjectName:'', FieldsMap: [] };
		}

		if (!newSections2[sectionIndex]?.validationMessages?.FieldsMap[groupIndex]) {
			newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex] = {};
		}
		const { FormFieldName, OptimiserFieldName } = newSections2[sectionIndex].FieldsMap[groupIndex];
		if (field == "FormFieldName") newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex].FormFieldName = '';
		if (field == "OptimiserFieldName") newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex].OptimiserFieldName = '';
		// if(field == "FormFieldName" && formFieldError) newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex].FormFieldName = formFieldError;
		if (field == "FormFieldName" && !FormFieldName) {
			newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex].FormFieldName = 'Form Field is required.';
		} else if (field == "OptimiserFieldName" && !OptimiserFieldName) {
			newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex].OptimiserFieldName = 'Optimiser Field is required.';
		} else if (field == "FormFieldName" && OptimiserFieldName){
			const matchFound = newSections2[sectionIndex].ObjectFields.some((f: any) => {
				return fieldTypeMapping[fieldType]?.includes(f.UIDataType) && f.value === OptimiserFieldName;
			});
			if(!matchFound) newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex].FormFieldName = 'Please select valid form field type';
		}
		 else if (field == "OptimiserFieldName" && OptimiserFieldName) {
			let optimiserFieldMap: any = {};
			newSections2[sectionIndex].FieldsMap.forEach((element: any, index: number) => {
				if (index != groupIndex) {
					if (!optimiserFieldMap[element.OptimiserFieldName]) {
						optimiserFieldMap[element.OptimiserFieldName] = true;
					}
				}

			});

			if (optimiserFieldMap[OptimiserFieldName]) {
				newSections2[sectionIndex].validationMessages.FieldsMap[groupIndex].OptimiserFieldName = `Optimiser Field '${OptimiserFieldName}' is already mapped to a different Form Field '${optimiserFieldMap[OptimiserFieldName]}'.`;
			}
		}
		setSections(newSections2);
	}

	const validateObjectName = (newSections: any, sectionIndex: number, value: string = "") => {
		const { ObjectName } = newSections[sectionIndex];
		newSections[sectionIndex].validationMessages.ObjectName = '';
		if (!ObjectName) {
			newSections[sectionIndex].validationMessages.ObjectName = 'Object is required.';
		} else {
			let objectMap: any = {};
			newSections.forEach((element: any, index: number) => {
				if (index != sectionIndex) {
					if (!objectMap[element.ObjectName]) {
						objectMap[element.ObjectName] = true;
					}
				}

			});

			if (objectMap[ObjectName]) {
				newSections[sectionIndex].validationMessages.ObjectName = `Object '${ObjectName}' is already used in another section.`;
			}
		}
		setSections(newSections);
	}
	
	const validateUniqueValues = (newSections2: any) => {
		// Validate unique ObjectName
		newSections2 = JSON.parse(JSON.stringify([...newSections2]));
		const objectNames = new Set();
		const newSections = newSections2.map((section: any, sectionIndex: number) => {
			const validationMessages = {
				ObjectName: '',
				FieldsMap: section.FieldsMap.map(() => ({ FormFieldName: '', OptimiserFieldName: '' }))
			};
			const { ObjectName } = section;
			if (!ObjectName) {
				validationMessages.ObjectName = 'Object is required.';
			} else if (objectNames.has(ObjectName)) {
				validationMessages.ObjectName = `Object '${ObjectName}' is already used in another section.`;
			} else {
				objectNames.add(ObjectName);
			}

			// Validate FieldsMap
			const optimiserFieldMap: any = {};
			section.FieldsMap.forEach((FieldsMap: any, groupIndex: number) => {
				const { FormFieldName, OptimiserFieldName } = FieldsMap;
				
				const formField:any = items.find((item:any) => item.value === FormFieldName);
				const optimiserField = section.ObjectFields.find((field:any) => field.value === OptimiserFieldName);
				
				if (!FormFieldName) {
					validationMessages.FieldsMap[groupIndex].FormFieldName = 'Form Field is required.';
				}
				if (!OptimiserFieldName) {
					validationMessages.FieldsMap[groupIndex].OptimiserFieldName = 'Optimiser Field is required.';
				}
				if (OptimiserFieldName) {
					if (!optimiserFieldMap[OptimiserFieldName]) {
						optimiserFieldMap[OptimiserFieldName] = true;
					} else if (optimiserFieldMap[OptimiserFieldName]) {
						validationMessages.FieldsMap[groupIndex].OptimiserFieldName = `Optimiser Field '${OptimiserFieldName}' is already mapped to a different Form Field '${optimiserFieldMap[OptimiserFieldName]}'.`;
					}
				}

				// if(formField && optimiserField && formField.type === 'city' && optimiserField.UIDataType === 'lookup' && optimiserField.value !== "City"){
				// 	validationMessages.FieldsMap[groupIndex].FormFieldName = "City form field mapped only with city(lookup) optmiser field";
				// }else 
				
				if(formField && optimiserField && !fieldTypeMapping[formField.type].includes(optimiserField.UIDataType)){
					validationMessages.FieldsMap[groupIndex].FormFieldName = "Please select valid form field type";
				}
			});

			return {
				...section,
				validationMessages
			};
		});

		setSections(newSections);
		const hasErrors = newSections.some((section: any) => {
			if (section.validationMessages.ObjectName) {
				return true;
			}
			return section.validationMessages.FieldsMap.some((group: any) => group.FormFieldName || group.OptimiserFieldName);
		});
		return !hasErrors;
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		try {
			if (validateUniqueValues(sections)) {
				dispatch(updateIntegration({ create: sections, action: actionName }));
				if (actionName == "update") {
					if (!intSettings) setFormEditable(true);
					handleNextStep(2);
				}else {
					const newSections = sections.map((section: any, i: number) => {
						return { ObjectName: section.ObjectName, FieldsMap: section.FieldsMap };
					});
					await axios.patch(`forms/integration-setting/` + formData._id, { target: "Optimiser", value: { create: newSections, action: "create",status:formStatus } });
					
					await Success({ title: "Settings saved" })
					setIsFormDisabled(true);
					setIsEdit(true);
				}
			} else {
				console.log("Form is invalid.");
			}
		} catch (error) {
			console.log(error);
			Error({ title: "Something went wrong!" })
		}

	};

	const resetCreatForm = async (e: any) => {
		e.preventDefault()
		//Warning({ title: "Cancel" });
		let data = await Confirm({
			html: `<span style='font-size:15px; color:#686868;'>Are you sure you want to discard the Integration set-up?</span>`,
			confirmButtonText: `<span style='font-size:14px'>Discard  Changes</span>`,
			cancelButtonText: `<span style='font-size:14px'>Go Back</span>`,

		})
		if (data.isConfirmed) {
			if (integrationData && integrationData.create) {
				setActionName(integrationData.action);
				setSections(integrationData.create);
			}else if (intSettings && intSettings.Optimiser && intSettings.Optimiser.create) {
				setActionName(intSettings.Optimiser.action);
				let newSections = intSettings.Optimiser.create.map((s: any) => {
					let selectedObject: any = optimiserObjects.find((ob: any) => ob.Name == s.ObjectName);
					let fieldsMap: any = [];
					if (selectedObject) {
						s.FieldsMap.forEach((element: any) => {
							fieldsMap.push({ FormFieldName: '', OptimiserFieldName: '' })
						});
					}
					return {
						FieldsMap: s.FieldsMap,
						ObjectName: s.ObjectName,
						ObjectFields: selectedObject ? selectedObject.Fields.map((f: any) => ({ value: f.Name, label: f.DisplayName + "(" + f.UIDataType + ")", UIDataType:f.UIDataType, IsRequired:f?.IsRequired, DefaultValue:f.DefaultValue})) : [],
						validationMessages: {
							ObjectName: "",
							FieldsMap: fieldsMap,
						}
					}
				})
				setSections(newSections);
			} else {
				setSections(initialSections);
			}
			setIsFormDisabled(true);
		}
	}

	const handleActionChange = (value: string) => {
		setActionName(value)
	}

	const editIntegrationForm = () => {
		setIsFormDisabled(false)
		setFormEditable(true)
	}

	const getPreviousSectionIds = (sectionIndex: number) => {
		let items = [];
		if (sectionIndex != 0) {
			for (let index = 0; index < sectionIndex; index++) {
				if (sections[index].ObjectName) items.push({ value: `${sections[index].ObjectName} record _id`, label: `${sections[index].ObjectName} record _id` });
			}
		}
		return items;
	}

	const changeFormStatus = async()=>{
		try {
			if(formStatus == "Enable"){
				let data = await Confirm({
					html: `<span style='font-size:15px; color:#686868;'>This will disconnect data saving in Optimiser. Would you like  to proceed?</span>`,
					confirmButtonText: `<span style='font-size:14px'>Yes</span>`,
					cancelButtonText: `<span style='font-size:14px'>No</span>`,
		
				})
				if (data.isConfirmed) {
					await axios.post(`forms/change-integration-status`, {_id:formData._id,status:"Disable" });
					await Success({ title: "Settings saved" })
					setFormStatus("Disable");
				}
			}else{
				await axios.post(`forms/change-integration-status`, {_id:formData._id,status:"Enable"  });
				await Success({ title: "Settings saved" })
				setFormStatus("Enable");
			}
		} catch (error) {
			console.log(error);
			Error({ title: "Something went wrong!" })
		}
		
		
	}

	return (
		<>
			<div className="d-flex d-flex-2">
				<h2 className="text-primary">Optimiser CRM Integration</h2>
				<div className="text-right">
					{isFormDisabled && <>
						<button className="btn btn-secondary mr-2" onClick={editIntegrationForm}>
							Edit
						</button>
						
					</>
					}
					{

						isEdit && <>
						<button className="btn-outline with-border" onClick={changeFormStatus}>
						{formStatus == "Enable" ? "Disable" : "Enable"}
						</button>
						</>
					}
				</div>
			</div>
			<form className="pt-2">
				<div className="form-group">
					<label>Action</label>
					<select className="form-control rounded-sm"  {...(isFormDisabled && { disabled: true })} onChange={(e: any) => handleActionChange(e.target.value)} value={actionName} >
						<option value="create">Create record</option>
						<option value="update">Create and update a record</option>

					</select>
				</div>
				{sections.map((section, sectionIndex) => (
					<FormSection
						key={sectionIndex}
						section={section}
						sectionIndex={sectionIndex}
						optimiserObjects={optimiserObjects}
						items={items}
						handleObjectChange={handleObjectChange}
						handleInputChange={handleInputChange}
						handleAddField={handleAddField}
						handleDeleteField={handleDeleteField}
						validationMessages={section.validationMessages}
						isFormDisabled={isFormDisabled}
						getPreviousSectionIds={getPreviousSectionIds}
						handleObjectDelete={handleObjectDelete}
						filteredObjectFields={filteredObjectFields}
					/>
				))}

				<div className={"text-right pt-3 " + " " + (isFormDisabled && "is-disabled")}>
					<button type="submit" className="btn btn-secondary add-object-btn" onClick={handleAddSection}>
						Add Object
					</button>
				</div>


				<hr />

				<div className={"text-right"}>
					<button className={"btn-outline mr-2 with-border" + " " + (isFormDisabled && "is-disabled")} onClick={resetCreatForm}>
						Cancel
					</button>
					{
						actionName == "create" ? (
							<button className={"btn btn-primary" + " " + (isFormDisabled && "is-disabled")} onClick={handleSubmit}>
								{isEdit ? "Save" : "Create"}
							</button>
						) : (
							<button className="btn btn-primary" onClick={handleSubmit}>
								Next
							</button>
						)
					}

				</div>
			</form>
		</>
	);
};

export default CreateRecord;
